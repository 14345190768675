<template>
    <Content>
        <template slot="content">
            <market-place-header :urls="pathUrls" />

            <approve-popup
                ref="approvePopup"
                action-btn-text="Mint"
                @action-btn-click="mintCharacter"
            />

            <mint-success-popup ref="mintSuccessPopup"/>

            <Loader v-if="isListLoading"/>

            <div v-else class="nft-cards">
                    <mint-nft-card
                        v-for="(character, i) in GET_CHARACTERS"
                        :key="i"
                        class="nft-cards__item"
                        :transitionDuration="i * 100"
                        v-bind="character"
                        @click="onCardClick(character)"
                        @btn-click="onNftMintClick(character)"
                    />
            </div>

            <preloader translucent black v-if="isMinting" />
        </template>
    </Content>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import mintMixin from "@/mixins/mintMixin";
import Content from '@/components/common/content.vue'
import MintNftCard from '@/components/common/mint-nft-card.vue'
import MarketPlaceHeader from '@/components/market-place/header.vue'
import Loader from '@/components/common/loader.vue'

export default {
    name: 'MintList',
    mixins: [mintMixin],
    components: {
        Content,
        MintNftCard,
        MarketPlaceHeader,
        Loader,
    },
    computed: {
        ...mapGetters([
            'GET_IS_MOBILE',
            'GET_WINDOW_WIDTH',
            'GET_CHARACTERS',
        ]),
    },
    data() {
        return {
            isListLoading: true,
            pathUrls: [
                {
                    label: 'Marketplace',
                    name: 'Market',
                },
                {
                    label: 'Mint',
                    name: 'Mint',
                },
            ]
        }
    },
    async mounted() {
        if (!this.GET_CHARACTERS.length) {
            await this.REQUEST_CHARACTERS()
        }

        this.isListLoading = false;
    },
    methods: {
        ...mapActions([
            'REQUEST_CHARACTERS',
            'getSmartContract',
            'getMFToken',
        ]),

        onCardClick(character) {
            this.$router.push({
                name: 'mintingSingle',
                params: {
                    person: character.type,
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
