var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade", mode: "out-in" } },
    [
      _vm.isMounted
        ? _c(
            "card-wrapper",
            {
              staticClass: "nft-card",
              attrs: {
                contentClass: "nft-card__content",
                image: _vm.image,
                "web-images": _vm.web_images,
                level: _vm.level,
                type: _vm.type,
                tier: 1,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("template", { slot: "content" }, [
                _c("div", { staticClass: "nft-card__title" }, [
                  _vm._v(" " + _vm._s(_vm.name) + " "),
                ]),
                _c(
                  "div",
                  { staticClass: "nft-card-info" },
                  [
                    _c(
                      "div",
                      { staticClass: "nft-card__stats" },
                      _vm._l(_vm.stats, function (attribute, i) {
                        return _c(
                          "tiny-stat",
                          _vm._b(
                            {
                              key: i,
                              staticClass: "nft-card__stats-item tiny-stat",
                              attrs: { isSmall: "" },
                            },
                            "tiny-stat",
                            attribute,
                            false
                          )
                        )
                      }),
                      1
                    ),
                    _c("btn", {
                      staticClass: "nft-card__btn",
                      attrs: {
                        type: "mint-gold",
                        text: "mint ? MF",
                        selected: _vm.toLocaleFormat(_vm.price),
                        hoverText: `MINT ${
                          _vm.toLocaleFormat(_vm.usd_price) || 100
                        } $`,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("btn-click")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          )
        : _c("div", { staticClass: "bg-transparent" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }