var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Content",
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("market-place-header", { attrs: { urls: _vm.pathUrls } }),
          _c("approve-popup", {
            ref: "approvePopup",
            attrs: { "action-btn-text": "Mint" },
            on: { "action-btn-click": _vm.mintCharacter },
          }),
          _c("mint-success-popup", { ref: "mintSuccessPopup" }),
          _vm.isListLoading
            ? _c("Loader")
            : _c(
                "div",
                { staticClass: "nft-cards" },
                _vm._l(_vm.GET_CHARACTERS, function (character, i) {
                  return _c(
                    "mint-nft-card",
                    _vm._b(
                      {
                        key: i,
                        staticClass: "nft-cards__item",
                        attrs: { transitionDuration: i * 100 },
                        on: {
                          click: function ($event) {
                            return _vm.onCardClick(character)
                          },
                          "btn-click": function ($event) {
                            return _vm.onNftMintClick(character)
                          },
                        },
                      },
                      "mint-nft-card",
                      character,
                      false
                    )
                  )
                }),
                1
              ),
          _vm.isMinting
            ? _c("preloader", { attrs: { translucent: "", black: "" } })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }